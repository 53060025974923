.mock {
    background-color: #000;
    opacity: 0.5;
    width: 100%;
    position: absolute;
    z-index: 100;
    top: 0;
    height: 100%;
}

.float-sider {
    position: absolute;
    color: #ffffff;
    z-index: 101;
    left: 50%;
    top: 50%;
    line-height: 1.5;
    text-align: center;
    width: 800px;
    transform: translate(-50%, -50%);
}

.ant-carousel {
    line-height: 0;
}

.float-sider span {
    font-weight: bold;
    font-size: 48px;
    display: block;
}

.float-sider button {
    background: none;
    border: solid #fff 2px;
    cursor: pointer;
}


.demo-video {
    height: 475px;
    padding: 75px 15%;
    overflow: hidden;
    background-image: url(../../resources/img_bg_2.png);
}

.demo-video > div {
    width: 48%;
    float: left;
}

.demo-video h1 {
    line-height: 42px;
    font-size: 42px;
}

.demo-video h6 {
    line-height: 18px;
    font-size: 12px;
}

.demo-video h3 {
    font-size: 24px;
    font-weight: bold;
}

.demo-video h4 {
    margin-top: 15px;
    font-size: 16px;
    color: red;
}

.demo-video .video {
    border-radius: 30px;
    height: 280px;
    position: relative;
}

.demo-video video.video {
    max-width: 100%;
}

.demo-video h1,h2,h3,h4,h5,h6 {
    color: #ffffff;
    text-align: right;
}

.demo-video .video-ctl {
    width: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    cursor: pointer;
    transform: translate(-50%, -50%);
}

.carousel {
    position: relative;
}